.AddBar {
  display: grid;
  grid-template-columns: 1fr 4em;
  // column-gap: 8px;

  .showNameInputContainer {
    .showNameInput {
      width: 100%;
      font-family: 'Playfair Display', serif;
      font-size: 30px;
    }
  }

  .searchResults {
    position: relative;
    display: none;

    .searchResultsInner {
      position: absolute;
      background-color: white;
      margin: 0;
      margin-left: 0px;
      width: 100%;
      border: 1px solid var(--main-bd-color);
      border-radius: 5px;
      padding: 4px;
      list-style: none;
      font-family: 'Playfair Display', serif;
      font-size: 30px;
      display: grid;
      grid-template-columns: auto 1fr;

      .title,
      .poster {
        cursor: pointer;
      }

      .poster {
        height: 50px;
        vertical-align: middle;
      }
    }
  }
}
