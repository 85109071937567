@import url(https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap);
* {
  box-sizing: border-box;
}

:root {
  --main-bg-color: #dff9fb;
  --main-bd-color: #4834d4;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #dff9fb;

  background-color: var(--main-bg-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{margin:8px;display:grid;grid-template-rows:1.5em auto 1fr 2em;height:100vh}

.Header .avatar{height:1em;vertical-align:text-top}

.AddBar{display:grid;grid-template-columns:1fr 4em}.AddBar .showNameInputContainer .showNameInput{width:100%;font-family:'Playfair Display', serif;font-size:30px}.AddBar .searchResults{position:relative;display:none}.AddBar .searchResults .searchResultsInner{position:absolute;background-color:white;margin:0;margin-left:0px;width:100%;border:1px solid var(--main-bd-color);border-radius:5px;padding:4px;list-style:none;font-family:'Playfair Display', serif;font-size:30px;display:grid;grid-template-columns:auto 1fr}.AddBar .searchResults .searchResultsInner .title,.AddBar .searchResults .searchResultsInner .poster{cursor:pointer}.AddBar .searchResults .searchResultsInner .poster{height:50px;vertical-align:middle}

.ListSection .ListSectionItem{font-family:'Playfair Display', serif;font-size:30px}

